import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { IoSearchOutline } from 'react-icons/io5';
import debounce from 'lodash.debounce';

const SearchBar = ({ onChange, placeholder }) => {
  const debouncedOnChange = debounce(value => onChange(value), 500);

  const handleSearchChange = useCallback(
    ({ target }) => {
      const value = target.value.trim();
      debouncedOnChange(value);
    },
    [debouncedOnChange],
  );

  return (
    <Box maxW={500}>
      <InputGroup>
        <Input
          id='search-bar'
          data-testid='search-bar-input'
          placeholder={placeholder}
          onChange={handleSearchChange}
        />
        <InputRightElement>
          <Icon as={IoSearchOutline} boxSize='5' />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SearchBar;
