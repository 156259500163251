import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
import capitalize from 'lodash.capitalize';
import i18n from 'lib/i18n';
import { tType, zoneType } from 'types';
import ZoneEdition from 'containers/Zones/ZoneEdition';
import DeleteZoneModal from 'components/DeleteZoneModal';
import Link from 'next/link';

const { withTranslation } = i18n;

const ZoneActions = ({ t, zoneSelected }) => {
  const {
    isOpen: isEditionOpen,
    onOpen: onOpenEdition,
    onClose: onCloseEdition,
  } = useDisclosure();
  const {
    isOpen: isDeletionOpen,
    onOpen: onOpenDeletion,
    onClose: onCloseDeletion,
  } = useDisclosure();
  return (
    <>
      <Flex justifyContent='center'>
        <Menu>
          <MenuButton data-testid='zone-options'>
            <Icon as={IoEllipsisVerticalSharp} />
          </MenuButton>
          <MenuList>
            <Link
              href={{
                pathname: '/monitors',
                query: { zoneId: zoneSelected.id, zoneName: zoneSelected.name },
              }}
            >
              <MenuItem hidden={zoneSelected.tankCount === 0}>
                {capitalize(t('zones_page.monitors_list'))}
              </MenuItem>
            </Link>
            {!zoneSelected.default && (
              <>
                <MenuItem onClick={onOpenEdition}>
                  {capitalize(t('edit'))}
                </MenuItem>
                <MenuItem
                  data-testid='delete-zone-button'
                  onClick={onOpenDeletion}
                >
                  <Text color='epsilon.500'>{capitalize(t('delete'))}</Text>
                </MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      </Flex>
      <ZoneEdition
        isOpen={isEditionOpen}
        close={onCloseEdition}
        zoneSelected={zoneSelected}
      />
      <DeleteZoneModal
        zone={zoneSelected}
        isOpen={isDeletionOpen}
        close={onCloseDeletion}
      />
    </>
  );
};

ZoneActions.propTypes = {
  t: tType,
  zoneSelected: zoneType,
};

export default withTranslation()(ZoneActions);
