import { useToast } from '@chakra-ui/react';
import ZoneModal from 'components/ZoneModal';
import i18n from 'lib/i18n';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TOAST_STATUS } from 'utils/constants';
import capitalize from 'lodash.capitalize';
import { tType, zoneType } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentCompanyId } from 'lib/auth';
import { getCurrentUser } from 'stores/currentUser';
import {
  fetchZonesThunk,
  getAllZonesByCompany,
  removeAllCompanyZones,
  updateZoneThunk,
} from 'stores/zones';

const { withTranslation } = i18n;

const ZoneEditionContainer = ({ t, isOpen, close, zoneSelected }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const currentCompanyId = getCurrentCompanyId();
  const { isSubTenant } = useSelector(getCurrentUser);
  const { items } = useSelector(getAllZonesByCompany) || [];
  const handleToastMessage = useCallback(
    error => {
      toast({
        title: '',
        description: error
          ? error.message
          : capitalize(t('zones_page.zone_updated_successfully')),
        status: error ? TOAST_STATUS.ERROR : TOAST_STATUS.SUCCESS,
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    },
    [t, toast],
  );

  const handleSubmit = useCallback(
    async ({ zoneName, parentZoneId, zoneBillable }) => {
      const { id } = zoneSelected;
      const params = {
        id,
        billable: zoneBillable,
        name: zoneName,
        parentId: parentZoneId,
        companyId: currentCompanyId,
      };
      const { error } = await dispatch(updateZoneThunk({ params }));
      if (!error) {
        await dispatch(removeAllCompanyZones());
        await dispatch(fetchZonesThunk({ companyId: currentCompanyId }));
      }

      handleToastMessage(error);
    },
    [currentCompanyId, dispatch, handleToastMessage, zoneSelected],
  );

  return (
    <ZoneModal
      isEdit
      isOpen={isOpen}
      close={close}
      zones={items}
      isSubTenant={isSubTenant}
      submitData={handleSubmit}
      zoneSelected={zoneSelected}
    />
  );
};

ZoneEditionContainer.propTypes = {
  t: tType,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  zoneSelected: zoneType,
};

export default withTranslation()(ZoneEditionContainer);
