/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const WithLink = ({ shouldHaveLink, queryParams, children }) =>
  shouldHaveLink ? (
    <Link href={{ pathname: '/monitors', query: queryParams }}>
      <a>{children}</a>
    </Link>
  ) : (
    children
  );

WithLink.propTypes = {
  shouldHaveLink: PropTypes.bool,
  queryParams: PropTypes.shape({
    zoneId: PropTypes.number,
    AlarmFilterField: PropTypes.string,
  }),
  children: PropTypes.shape({}),
};

export default WithLink;
