import React from 'react';
import PropTypes from 'prop-types';
import { Tr, Td, Text, Icon } from '@chakra-ui/react';
import { ROLES } from 'utils/constants';
import { IoCheckmarkOutline } from 'react-icons/io5';
import BillableIcon from 'components/icons/BillableIcon';
import { COLUMN_HEADERS } from '../constants';
import AlarmBox from './AlarmBox';
import ZoneIndentator from './ZoneIndentator';
import ZoneActions from './ZoneActions';

const ZonesTableRow = ({
  zone,
  handleChildZonesVisibility,
  indentation,
  isModalDisplay,
  assignAsNewParentZone,
  newParentZoneId,
  isCheckboxSelection,
  submitCheckZone,
  currentUserRole,
  isProfile,
  isModal,
  isSubTenant,
  isInitiallyExpanded,
}) => {
  return (
    <Tr
      data-testid='zone-row'
      hidden={zone.hidden}
      key={zone.id}
      {...(isModalDisplay &&
        !isCheckboxSelection && {
          onClick: () => {
            assignAsNewParentZone(zone.id);
          },
        })}
      cursor={isModalDisplay && !isCheckboxSelection && 'pointer'}
    >
      {COLUMN_HEADERS.map(
        ({ attribute, subAttribute, bg, alarmFilterField }) => {
          const attributeValue = subAttribute
            ? zone[attribute][subAttribute]
            : zone[attribute];

          const nameTd = (
            <Td key={`${zone.id}-${attribute}`} paddingInlineStart='4'>
              <ZoneIndentator
                isProfile={isProfile}
                isModal={isModal}
                indentation={indentation}
                attributeValue={attributeValue}
                zone={zone}
                handleChildZonesVisibility={handleChildZonesVisibility}
                isModalDisplay={isModalDisplay}
                newParentZoneId={newParentZoneId}
                isCheckboxSelection={isCheckboxSelection}
                submitCheckZone={submitCheckZone}
                isSubTenant={isSubTenant}
                isInitiallyExpanded={isInitiallyExpanded}
              />
            </Td>
          );
          const billableTd = (
            <Td
              hidden={isModalDisplay}
              key={`${zone.id}-${attribute}-${subAttribute}`}
              textAlign='center'
            >
              <Icon
                as={BillableIcon}
                data-testid='zone-billable-icon'
                hidden={!zone.billable || isCheckboxSelection || isSubTenant}
                boxSize='5'
              />
              <Icon
                hidden={zone.id !== newParentZoneId}
                boxSize='5'
                as={IoCheckmarkOutline}
              />
            </Td>
          );
          const monitorsTd = (
            <Td
              hidden={isModalDisplay}
              key={`${zone.id}-${attribute}-${subAttribute}`}
              textAlign='center'
            >
              <Text ml='auto' mr='1'>
                <strong>{zone.tankCount}</strong>
              </Text>
            </Td>
          );
          const alarmBox = (
            <Td
              hidden={isModalDisplay}
              key={`${zone.id}-${attribute}-${subAttribute}`}
            >
              <AlarmBox
                bg={bg}
                value={attributeValue}
                alarmFilterField={alarmFilterField}
                id={zone.id}
              />
            </Td>
          );
          if (attribute === 'name') return nameTd;
          if (attribute === 'billable') return billableTd;
          if (attribute === 'monitors') return monitorsTd;
          return alarmBox;
        },
      )}
      {currentUserRole !== ROLES.commonUser && (
        <Td hidden={isModalDisplay}>
          <ZoneActions zoneSelected={zone} />
        </Td>
      )}
    </Tr>
  );
};

ZonesTableRow.propTypes = {
  zone: PropTypes.shape({
    hidden: PropTypes.bool,
    id: PropTypes.number,
    tankCount: PropTypes.number,
    zones: PropTypes.arrayOf(PropTypes.shape()),
    billable: PropTypes.bool,
  }),
  handleChildZonesVisibility: PropTypes.func,
  indentation: PropTypes.number,
  isModalDisplay: PropTypes.bool,
  assignAsNewParentZone: PropTypes.func,
  newParentZoneId: PropTypes.number,
  isCheckboxSelection: PropTypes.bool,
  submitCheckZone: PropTypes.func,
  currentUserRole: PropTypes.string,
  isProfile: PropTypes.bool,
  isModal: PropTypes.bool,
  isSubTenant: PropTypes.bool,
  isInitiallyExpanded: PropTypes.bool,
};

export default ZonesTableRow;
