import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Thead, Tbody, Tr, Th, Text, Box, Flex } from '@chakra-ui/react';
import i18n from 'lib/i18n';
import { tType } from 'types';
import capitalize from 'lodash.capitalize';
import { ROLES } from 'utils/constants';
import { getFlatZones } from 'utils/zones';
import { COLUMN_HEADERS } from './constants';
import ZonesTableRow from './ZonesTableRow';

const { withTranslation } = i18n;

const ZonesTable = ({
  mapContainer,
  zones,
  isModalDisplay,
  assignAsNewParentZone,
  newParentZoneId = null,
  isCheckboxSelection,
  selectCheckedZones,
  checkedZoneIds,
  currentUserRole,
  isSubTenant,
  alarm,
  isInMapMode,
  monitorsWithCoordinates,
  isProfile,
  isModal,
  t,
}) => {
  const setHiddenProperty = useCallback(
    (zoneList, isHidden) =>
      zoneList &&
      zoneList.map(zone => ({
        ...zone,
        hidden: isHidden,
        zones: zone.zones.length > 0 ? setHiddenProperty(zone.zones, true) : [],
      })),
    [],
  );

  const getParentZoneIds = (list, zoneId, parentIds = []) => {
    list.forEach(element => {
      if (element.id === zoneId) {
        parentIds.push(element.id);
        if (element.parentId) {
          getParentZoneIds(list, element.parentId, parentIds);
        }
      }
    });

    return parentIds;
  };

  const assignFirstCheckStatus = zoneList =>
    zoneList.map(zone => ({
      ...zone,
      checked: checkedZoneIds && checkedZoneIds.includes(zone.id),
      zones: zone.zones.length > 0 ? assignFirstCheckStatus(zone.zones) : [],
    }));

  const getInitiallyExpandedZoneIds = (flatZones, checkedIds) => {
    if (!checkedIds || checkedIds.length === 0) {
      return [];
    }
    return checkedIds.flatMap(id => getParentZoneIds(flatZones, id));
  };

  const assignFirstHiddenStatus = (zoneList, expandedIds) => {
    if (expandedIds.length === 0) {
      return setHiddenProperty(zoneList, false);
    }
    // zone will be set to hidden: false if it has no parentId (top-level zone) or
    // if the zone has a parent that is expanded
    const assignHidden = (list, isTopLevel = false) =>
      list.map(zone => ({
        ...zone,
        hidden: isTopLevel ? false : !expandedIds.includes(zone.parentId),
        zones: zone.zones.length > 0 ? assignHidden(zone.zones, false) : [],
      }));

    return assignHidden(zoneList, true);
  };

  const setCheckedProperty = useCallback(
    (zoneList, isChecked = null) =>
      zoneList.map(zone => ({
        ...zone,
        checked: isChecked,
        zones:
          zone.zones.length > 0
            ? setCheckedProperty(zone.zones, isChecked)
            : [],
      })),
    [],
  );

  const selectedZoneIds = newParentZoneId ? [newParentZoneId] : checkedZoneIds;
  const initiallyExpandedZoneIds = getInitiallyExpandedZoneIds(
    getFlatZones(zones),
    selectedZoneIds,
  );
  const zonesWithHiddenProperty = assignFirstHiddenStatus(
    zones,
    initiallyExpandedZoneIds,
  );
  const [zonesList, setZonesList] = useState(
    assignFirstCheckStatus(zonesWithHiddenProperty),
  );

  const changeParentZoneCheckProperty = (zoneList, zoneId) => {
    const parentIds = getParentZoneIds(getFlatZones(zoneList), zoneId);
    return zoneList.map(zone => ({
      ...zone,
      ...(parentIds.includes(zone.id) && { checked: false }),
      zones:
        zone.zones.length > 0
          ? changeParentZoneCheckProperty(zone.zones, zoneId)
          : [],
    }));
  };

  const checkAlarmFiltersMatch = zone => {
    return zone.alarmCount[alarm?.replaceAll('_', '')] === 0;
  };

  const arrangeZonesVisibility = (zoneId, areChildZonesExpanded, zoneList) =>
    zoneList.map(zone => ({
      ...zone,
      ...(zoneId === zone.id
        ? {
            zones: !areChildZonesExpanded
              ? setHiddenProperty(zone.zones, true)
              : zone.zones.map(childZone => {
                  return {
                    ...childZone,
                    hidden: false || checkAlarmFiltersMatch(childZone),
                  };
                }),
          }
        : {
            zones:
              zone.zones.length > 0
                ? arrangeZonesVisibility(
                    zoneId,
                    areChildZonesExpanded,
                    zone.zones,
                  )
                : [],
          }),
    }));

  const arrangeZonesWithCheckStatus = (zoneId, isZoneChecked, zoneList) =>
    zoneList.map(zone => ({
      ...zone,
      ...(zoneId === zone.id
        ? {
            checked: isZoneChecked,
            zones: setCheckedProperty(zone.zones, isZoneChecked),
          }
        : {
            zones:
              zone.zones.length > 0
                ? arrangeZonesWithCheckStatus(zoneId, isZoneChecked, zone.zones)
                : [],
          }),
    }));

  const handleChildZonesVisibility = (zoneId, hide, zoneList = zonesList) => {
    setZonesList(arrangeZonesVisibility(zoneId, hide, zoneList));
  };

  const handleZoneChecked = (data, zoneList = zonesList) => {
    if (!data.checked) {
      const list = arrangeZonesWithCheckStatus(
        data.zoneId,
        data.checked,
        changeParentZoneCheckProperty(zoneList, data.zoneId),
      );
      setZonesList(list);
      selectCheckedZones(
        getFlatZones(list)
          .filter(zone => zone.checked)
          .map(checkedZones => checkedZones.id),
      );
    } else {
      const list = arrangeZonesWithCheckStatus(
        data.zoneId,
        data.checked,
        zoneList,
      );
      setZonesList(list);
      selectCheckedZones(
        getFlatZones(list)
          .filter(zone => zone.checked)
          .map(checkedZones => checkedZones.id),
      );
    }
  };
  const createZoneRows = (list, indentation = 0) =>
    list.map(zone => (
      <React.Fragment key={zone.id}>
        <ZonesTableRow
          isProfile={isProfile}
          isModal={isModal}
          zone={zone}
          handleChildZonesVisibility={handleChildZonesVisibility}
          indentation={indentation}
          isModalDisplay={isModalDisplay}
          assignAsNewParentZone={assignAsNewParentZone}
          newParentZoneId={newParentZoneId}
          isCheckboxSelection={isCheckboxSelection}
          submitCheckZone={handleZoneChecked}
          currentUserRole={currentUserRole}
          isSubTenant={isSubTenant}
          isInitiallyExpanded={initiallyExpandedZoneIds.includes(zone.id)}
        />
        {zone.zones.length > 0 && createZoneRows(zone.zones, indentation + 4)}
      </React.Fragment>
    ));

  return (
    <Box height='100%'>
      {isInMapMode && (
        <Flex
          direction='column'
          position='relative'
          flex='1'
          h='100%'
          p='2'
          justifyContent='center'
        >
          {monitorsWithCoordinates.length > 0 && mapContainer ? (
            <Box w='100%' h='100%' ref={mapContainer} />
          ) : (
            <Text textAlign='center' mt='1' variant='headingMd'>
              {capitalize(t('map.no_monitors_message'))}
            </Text>
          )}
        </Flex>
      )}
      {!isInMapMode && (
        <Box mt={16} p={!isModalDisplay && '6'} rounded='lg' bg='white'>
          <Table variant='simple' p='0'>
            <Thead hidden={isModalDisplay}>
              <Tr>
                {COLUMN_HEADERS.map(({ name }) => {
                  return (
                    <Th
                      key={name}
                      style={{
                        visibility:
                          name === 'billable' && isSubTenant
                            ? 'hidden'
                            : 'visible',
                      }}
                    >
                      <Text textAlign={name !== 'zone' && 'center'}>
                        {t(`table_columns.${name}`)}
                      </Text>
                    </Th>
                  );
                })}
                {currentUserRole !== ROLES.commonUser && (
                  <Th>
                    <Text textAlign='center'>{capitalize(t('actions'))}</Text>
                  </Th>
                )}
              </Tr>
            </Thead>
            <Tbody>{zonesList && createZoneRows(zonesList)}</Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

ZonesTable.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.shape()),
  mapContainer: PropTypes.shape({}),
  isModalDisplay: PropTypes.bool,
  assignAsNewParentZone: PropTypes.func,
  newParentZoneId: PropTypes.number,
  isCheckboxSelection: PropTypes.bool,
  selectCheckedZones: PropTypes.func,
  checkedZoneIds: PropTypes.arrayOf(PropTypes.number),
  currentUserRole: PropTypes.string,
  isSubTenant: PropTypes.bool,
  alarm: PropTypes.string,
  isInMapMode: PropTypes.bool,
  monitorsWithCoordinates: PropTypes.arrayOf(PropTypes.shape()),
  isProfile: PropTypes.bool,
  isModal: PropTypes.bool,
  t: tType,
};

export default withTranslation()(ZonesTable);
