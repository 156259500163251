import { Checkbox, Flex, Icon, Text } from '@chakra-ui/react';
import i18n from 'lib/i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  IoChevronForwardOutline,
  IoChevronDownOutline,
  IoCheckmarkOutline,
} from 'react-icons/io5';

import BillableIcon from 'components/icons/BillableIcon';
import WithLink from '../WithLink';

const { withTranslation } = i18n;

const ZoneIndentator = ({
  indentation,
  attributeValue,
  zone,
  handleChildZonesVisibility,
  isModalDisplay,
  newParentZoneId,
  isCheckboxSelection,
  submitCheckZone,
  isProfile,
  isModal,
  isSubTenant,
  isInitiallyExpanded,
}) => {
  const [areChildZonesExpanded, setAreChildZonesExpanded] = useState(
    isInitiallyExpanded,
  );
  const hasChildZones = zone.zones.length > 0;

  const changeChildZonesVisibility = zoneId => {
    handleChildZonesVisibility(zoneId, !areChildZonesExpanded);
    setAreChildZonesExpanded(!areChildZonesExpanded);
  };

  useEffect(() => {
    if (zone.hidden) {
      setAreChildZonesExpanded(false);
    }
  }, [zone]);

  return (
    <Flex alignItems='center' ml={indentation}>
      {isCheckboxSelection && (
        <Checkbox
          hidden={isProfile}
          isChecked={zone.checked}
          mr='4'
          onChange={event => {
            submitCheckZone({ zoneId: zone.id, checked: event.target.checked });
          }}
        />
      )}

      <Icon
        data-testid={
          areChildZonesExpanded && hasChildZones
            ? `hide-icon-${zone.id}`
            : `show-icon-${zone.id}`
        }
        color={hasChildZones ? 'gray.900' : 'gray.300'}
        boxSize='5'
        cursor='pointer'
        as={
          areChildZonesExpanded && hasChildZones
            ? IoChevronDownOutline
            : IoChevronForwardOutline
        }
        onClick={e => {
          e.stopPropagation();
          changeChildZonesVisibility(zone.id);
        }}
      />
      <WithLink
        shouldHaveLink={zone.tankCount !== 0 && !isModalDisplay}
        queryParams={{ zoneId: zone.id, zoneName: zone.name }}
      >
        <Text
          cursor={zone.tankCount !== 0 && 'pointer'}
          color='gray.700'
          fontWeight='bold'
          fontSize='md'
          ml='3'
        >
          {attributeValue}
        </Text>
      </WithLink>
      {isModal && (
        <>
          <Text ml='auto' mr='1'>
            <strong>{zone.tankCount}</strong>
          </Text>
          <Icon
            as={BillableIcon}
            data-testid='zone-billable-icon'
            hidden={!zone.billable || isCheckboxSelection || isSubTenant}
            boxSize='5'
          />
          <Icon
            hidden={zone.id !== newParentZoneId}
            boxSize='5'
            as={IoCheckmarkOutline}
          />
        </>
      )}
    </Flex>
  );
};

ZoneIndentator.propTypes = {
  zone: PropTypes.shape({
    billable: PropTypes.bool,
    id: PropTypes.number,
    tankCount: PropTypes.number,
    zones: PropTypes.arrayOf(PropTypes.shape()),
    hidden: PropTypes.bool,
    name: PropTypes.string,
    checked: PropTypes.bool,
  }),
  isProfile: PropTypes.bool,
  attributeValue: PropTypes.string,
  handleChildZonesVisibility: PropTypes.func,
  indentation: PropTypes.number,
  isModalDisplay: PropTypes.bool,
  newParentZoneId: PropTypes.number,
  isCheckboxSelection: PropTypes.bool,
  submitCheckZone: PropTypes.func,
  isModal: PropTypes.bool,
  isSubTenant: PropTypes.bool,
  isInitiallyExpanded: PropTypes.bool,
};

export default withTranslation()(ZoneIndentator);
