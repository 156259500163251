export const COLUMN_HEADERS = [
  {
    name: 'zone',
    attribute: 'name',
  },
  {
    name: 'billable',
    attribute: 'billable',
  },
  {
    name: 'monitors',
    attribute: 'monitors',
  },
  {
    name: 'high',
    attribute: 'alarmCount',
    subAttribute: 'high',
    bg: 'beta.400',
    alarmFilterField: 'high',
  },
  {
    name: 'low',
    attribute: 'alarmCount',
    subAttribute: 'low',
    bg: 'zeta.400',
    alarmFilterField: 'low',
  },
  {
    name: 'low_battery',
    attribute: 'alarmCount',
    subAttribute: 'lowBattery',
    bg: 'epsilon.400',
  },
  // Commenting out until we implement late report alarms
  // {
  //   name: 'late_report',
  //   attribute: 'alarmCount',
  //   subAttribute: 'lateReports',
  //   bg: 'gray.400',
  // },
];
