import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import capitalize from 'lodash.capitalize';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'lib/i18n';
import { deleteZoneThunk, fetchZonesThunk } from 'stores/zones';
import { tType } from 'types';
import { MESSAGES } from 'utils/constants';

const { withTranslation } = i18n;

const calculateMessage = (error, t) => {
  if (!error) return t('zones_page.delete_confirmation');

  return error.message || MESSAGES.DEFAULT_ERROR;
};

const DeleteZoneModal = ({ close, isOpen, t, zone }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const handleDeleteConfirmation = async () => {
    close();

    const { error } = await dispatch(deleteZoneThunk({ zoneId: zone.id }));

    if (!error) dispatch(fetchZonesThunk({ companyId: zone.companyId }));

    handleToastMessage(error);
  };

  const handleToastMessage = error => {
    toast({
      title: '',
      description: calculateMessage(error, t),
      status: error ? 'error' : 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  };

  const header = `${capitalize(t('delete'))} ${capitalize(t('zone'))}`;

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent p={30}>
        <ModalHeader p={0}>{header}</ModalHeader>
        <ModalCloseButton margin='20px' />
        <ModalBody marginTop='16px' p={0}>
          <Text>{capitalize(t('zones_page.delete'))}</Text>
          <Text color='epsilon.500'>{capitalize(t('cant_undo'))}</Text>
          <Flex marginTop='20px'>
            <Spacer />
            <Button
              variant='ghost'
              data-testid='delete-confirmation-button'
              mr={3}
              onClick={handleDeleteConfirmation}
            >
              {capitalize(t('zones_page.delete_confirm'))}
            </Button>
            <Button mr={3} onClick={close}>
              {capitalize(t('cancel'))}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

DeleteZoneModal.propTypes = {
  t: tType,
  isOpen: PropTypes.bool,
  zone: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    companyId: PropTypes.number,
  }),
  close: PropTypes.func,
};

export default withTranslation()(DeleteZoneModal);
