import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import WithLink from '../WithLink';

const AlarmBox = ({ bg, value, id, alarmFilterField }) => (
  <WithLink
    shouldHaveLink={value !== 0}
    queryParams={{ zoneId: id, alarm: alarmFilterField }}
  >
    <Box
      cursor={value !== 0 && 'pointer'}
      fontWeight='bold'
      fontSize='xs'
      rounded='sm'
      textAlign='center'
      bg='white'
      borderColor={bg}
      border='1px'
      color={bg}
      width='fit-content'
      py='0.5'
      px='2'
      mx='auto'
      minW='2.5rem'
    >
      {value}
    </Box>
  </WithLink>
);

AlarmBox.propTypes = {
  bg: PropTypes.string,
  value: PropTypes.number,
  id: PropTypes.number,
  alarmFilterField: PropTypes.string,
};

export default AlarmBox;
