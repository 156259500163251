import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Billable = createIcon({
  displayName: 'Invoice Group',
  viewBox: '0 0 18 21',
  fill: 'none',
  path: (
    <svg
      width='18'
      height='21'
      viewBox='0 0 18 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='11' r='8.5' stroke='#414D58' />
      <path
        d='M11.916 13.284C11.916 15.1787 10.9313 16.126 8.962 16.126H8.752L8.556 17.736C8.08 17.708 7.842 17.6753 7.842 17.638L8.038 16.084L6.036 15.832L6.148 14.978C6.86667 15.0807 7.534 15.1507 8.15 15.188L8.612 11.688C7.66 11.464 6.97867 11.1793 6.568 10.834C6.16667 10.4793 5.966 9.90067 5.966 9.098C5.966 7.446 6.95533 6.62 8.934 6.62C9.074 6.62 9.18133 6.62467 9.256 6.634L9.494 4.828H10.208L9.97 6.676L11.72 6.886L11.636 7.754C10.992 7.67933 10.3993 7.61867 9.858 7.572L9.438 10.834C10.3433 11.0487 10.9827 11.324 11.356 11.66C11.7293 11.996 11.916 12.5373 11.916 13.284ZM7.002 9.042C7.002 9.546 7.128 9.90533 7.38 10.12C7.632 10.3347 8.08467 10.5213 8.738 10.68L9.144 7.53H9.004C7.66933 7.53 7.002 8.034 7.002 9.042ZM10.88 13.354C10.88 12.906 10.7633 12.5747 10.53 12.36C10.2967 12.1453 9.89067 11.968 9.312 11.828L8.878 15.216H8.906C10.222 15.216 10.88 14.5953 10.88 13.354Z'
        fill='black'
      />
    </svg>
  ),
});

export default Billable;
