import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Button,
  Box,
} from '@chakra-ui/react';
import ZonesTable from 'components/ZonesTable';
import capitalize from 'lodash.capitalize';
import { tType } from 'types';
import i18n from 'lib/i18n';

const { withTranslation } = i18n;

const formatName = value => {
  return value.replace(/[^\w\d\s]/g, '');
};

const ZoneModal = ({
  isOpen,
  close,
  zones,
  t,
  submitData,
  zoneSelected,
  isEdit,
  isMoveToZone,
  isCheckboxSelection,
  selectCheckedZonesIds,
  checkedZoneIds,
  isProfile,
  isSubTenant,
}) => {
  const [zoneName, setZoneName] = useState(
    zoneSelected ? zoneSelected.name : '',
  );
  const [zoneBillable, setZoneBillable] = useState(
    zoneSelected ? zoneSelected.billable : true,
  );
  const [parentZoneId, setParentZoneId] = useState(
    zoneSelected ? zoneSelected.parentId : null,
  );
  const [checkedZones, setCheckedZones] = useState([]);
  const isButtonAccesible = isMoveToZone ? parentZoneId : zoneName !== '';
  const setModalTitle = () => {
    if (isProfile) {
      return capitalize(t('zones'));
    }

    if (isEdit) {
      return capitalize(t('zones_page.edit_zone'));
    }

    if (isCheckboxSelection) {
      return capitalize(t('zones_page.select_zones'));
    }

    if (isMoveToZone) {
      return capitalize(t('zones_page.move_to_zone'));
    }

    return capitalize(t('zones_page.create_new_zone'));
  };

  const setModalButtonText = () => {
    if (isEdit) {
      return capitalize(t('save'));
    }

    if (isMoveToZone) {
      return capitalize(t('move'));
    }

    if (isCheckboxSelection) {
      return capitalize(t('select'));
    }

    return capitalize(t('create'));
  };

  const modalText = {
    button: setModalButtonText(),
    title: setModalTitle(),
  };

  const setNewParentZoneId = id => {
    if (id === parentZoneId) {
      setParentZoneId(null);
    } else {
      setParentZoneId(id);
    }
  };

  const cleanModal = () => {
    setParentZoneId(zoneSelected ? zoneSelected.parentId : null);
    setZoneName(zoneSelected ? zoneSelected.name : '');
    setZoneBillable(zoneSelected ? zoneSelected.billable : false);
    close();
  };

  const submitZone = () => {
    submitData(
      !isMoveToZone
        ? { zoneName, parentZoneId, zoneBillable }
        : { parentZoneId },
    );
    cleanModal();
  };

  const handleBillableClick = ({ target: { checked } }) => {
    setZoneBillable(checked);
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color='alpha.500' variant='headingXl'>
            {capitalize(modalText.title)}
          </Text>
        </ModalHeader>
        <ModalCloseButton onClick={cleanModal} />
        <ModalBody>
          <Box hidden={isMoveToZone || isCheckboxSelection}>
            <FormControl>
              <FormLabel>{capitalize(t('zones_page.name'))}</FormLabel>
              <Input
                onChange={({ target: { value } }) => {
                  const formattedName = formatName(value);

                  setZoneName(formattedName);
                }}
                data-testid='zone-creation-name-input'
                maxLength='30'
                value={zoneName}
              />
            </FormControl>
          </Box>
          <Box
            my='8'
            hidden={isMoveToZone || isCheckboxSelection || isSubTenant}
          >
            <FormControl>
              <Checkbox
                onChange={handleBillableClick}
                data-testid='zone-creation-billable-input'
                isChecked={zoneBillable}
              >
                {capitalize(t('zones_page.billable'))}
              </Checkbox>
            </FormControl>
          </Box>
          <Box mt='8' maxHeight='sm' overflowY='auto'>
            {!isProfile && (
              <FormLabel>{capitalize(t('zones_page.select_zone'))}</FormLabel>
            )}
            <ZonesTable
              isProfile={isProfile}
              isModal
              zones={
                isMoveToZone
                  ? zones
                  : zones.filter(zone => zone.default === false)
              }
              isModalDisplay
              assignAsNewParentZone={setNewParentZoneId}
              newParentZoneId={parentZoneId}
              isCheckboxSelection={isCheckboxSelection}
              selectCheckedZones={setCheckedZones}
              checkedZoneIds={checkedZoneIds}
              isSubTenant={isSubTenant}
            />
          </Box>

          <Flex mt='10' mb='4'>
            {!isProfile && (
              <>
                <Spacer />
                <Button mr='4' onClick={cleanModal} variant='link'>
                  {capitalize(t('cancel'))}
                </Button>

                {isCheckboxSelection ? (
                  <Button
                    data-testid='select-zone-info-button'
                    onClick={() => {
                      selectCheckedZonesIds(checkedZones);
                      close();
                    }}
                  >
                    {capitalize(modalText.button)}
                  </Button>
                ) : (
                  <Button
                    data-testid='submit-zone-info-button'
                    disabled={!isButtonAccesible}
                    onClick={submitZone}
                  >
                    {capitalize(modalText.button)}
                  </Button>
                )}
              </>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ZoneModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  zones: PropTypes.arrayOf(PropTypes.shape()),
  t: tType,
  submitData: PropTypes.func,
  zoneSelected: PropTypes.shape(),
  isEdit: PropTypes.bool,
  isMoveToZone: PropTypes.bool,
  isCheckboxSelection: PropTypes.bool,
  selectCheckedZonesIds: PropTypes.func,
  checkedZoneIds: PropTypes.arrayOf(PropTypes.number),
  isProfile: PropTypes.bool,
  isSubTenant: PropTypes.bool,
};

export default withTranslation()(ZoneModal);
